.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input {
  border: 1px solid rgb(228, 230, 239);
  padding: 9.75px 13px;
  border-radius: 5px;
}
